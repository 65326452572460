import { EventObject, Typestate } from 'xstate';
import { CustomerEligibility, GenerateBankConnectorTokenErrors, HardDecline, Order, SoftDecline, UpdateBankAccountErrors } from '../../gql/graphql';
import { PaymentSelectionErrors } from '../../pages/paymentSelectionError/PaymentSelectionError';

// Context Types
export type LongFormMachineContext = {
  order?: Order | null;
  eligibilityDetails?: CustomerEligibility | null;
  transitionedFrom?: LongFormStates | null;
  highestSupportedApplicationMilestone?: number | null;
  hardDeclineError?: HardDecline;
  softDeclineError?: SoftDecline;
  skipToPage?: string | null;
  isReadOnly?: boolean | null;
  bankAcctLast4: string;
  bankName: string;
  plaidFF: boolean;
  plaidAB: boolean;
  plaidLoading: boolean;
  paymentSelectionError?: PaymentSelectionErrors;
  orderId: string;
  plaidToken: {
    linkToken: string;
    publicToken: string;
  };
  isTokenizedAcct: boolean;
};

// State Types
export enum LongFormStates {
  Entry = 'Entry',
  PersonalInformation = 'personalInformation',
  ContactInformation = 'contactInformation',
  BillingAddress = 'billingAddress',
  IncomeSource = 'incomeSource',
  BankingInformation = 'bankingInformation',
  DebitCardInformation = 'debitCardInformation',
  ReviewAndSubmit = 'reviewAndSubmit',
  AmendAndResubmit = 'amendAndResubmit',
  TransferToRapid = 'transferToRapid',
  PreApproval = 'preApproval',
  CompleteAppLater = 'completeAppLater',
  Declined = 'declined',
  InternalError = 'internalError',
  Approval = 'approval',
  ApprovalConfirmation = 'approvalConfirmation',
  Ineligible = 'ineligible',
  Otb = 'otb',

  //Payment Selection/Plaid States
  PaymentSelection = 'PaymentSelection',
  PaymentSelectionError = 'PaymentSelectionError',
  PlaidModal = 'PlaidModal',
  PaymentReview = 'PaymentReview',
  FetchLinkToken = 'FetchLinkToken',
  UpdateBankAccount = 'UpdateBankAccount',

  // Errors Found States
  BankAccount = 'bankAccount',

  // info pages
  CustomerIdProgram = 'CustomerIdProgram',
  TermsAndConditions = 'TermsAndConditions',
  PrequalificationNotice = 'PrequalificationNotice',
  AuthorizedUserTerms = 'AuthorizedUserTerms',
}

export interface LongFormMachineStatesSchema {
  [LongFormStates.Entry]: {};
  [LongFormStates.PersonalInformation]: {};
  [LongFormStates.ContactInformation]: {};
  [LongFormStates.BillingAddress]: {};
  [LongFormStates.IncomeSource]: {};
  [LongFormStates.BankingInformation]: {};
  [LongFormStates.DebitCardInformation]: {};
  [LongFormStates.ReviewAndSubmit]: {};
  [LongFormStates.AmendAndResubmit]: {};
  [LongFormStates.TransferToRapid]: {};
  [LongFormStates.CustomerIdProgram]: {};
  [LongFormStates.TermsAndConditions]: {};
  [LongFormStates.PrequalificationNotice]: {};
  [LongFormStates.AuthorizedUserTerms]: {};
  [LongFormStates.Approval]: {};
  [LongFormStates.PreApproval]: {};
  [LongFormStates.CompleteAppLater]: {};
  [LongFormStates.Declined]: {};
  [LongFormStates.InternalError]: {};
  [LongFormStates.Ineligible]: {};
  [LongFormStates.Otb]: {};
  [LongFormStates.PaymentSelection]: {};
  [LongFormStates.PaymentSelectionError]: {};
  [LongFormStates.PaymentReview]: {};
  [LongFormStates.PlaidModal]: {};
  [LongFormStates.FetchLinkToken]: {};
}

export interface LongFormMachineStates extends Typestate<LongFormMachineContext> {
  type: LongFormMachineStatesSchema;
}

// Event Types
export enum LongFormEvents {
  NEXT = 'NEXT',
  BACK = 'BACK',
  ERROR = 'ERROR',
  CancelButtonClicked = 'CancelButtonClicked',

  // info page events
  VIEW_CUSTOMER_ID_PROGRAM = 'ViewCustomerIdProgram',
  VIEW_TERMS_AND_CONDITIONS = 'ViewTermsAndConditions',
  VIEW_PREQUALIFICATION_NOTICE = 'ViewPrequalificationNotice',
  VIEW_AUTHORIZED_USER_TERMS = 'ViewAuthorizedUserTerms',

  // pre-approved page events
  APPROVED = 'Approved',
  DECLINED_OR_ERROR = 'DeclinedOrError',
  COMPLETE_APP_LATER = 'CompleteAppLater',

  // Payment Selection/Plaid Events
  SELECT_PLAID = 'SelectPlaid',
  SELECT_MANUAL = 'SelectManual',
  FETCH_LINK_TOKEN = 'FetchLinkToken',
  PLAID_MODAL_LOADING = 'PlaidModalLoading',
  UPDATE_BANK_ACCOUNT = 'UpdateBankAccount',
}

type LongFormEventsTypeSchema =
  | LongFormEvents.NEXT
  | LongFormEvents.BACK
  | LongFormEvents.ERROR
  | LongFormEvents.CancelButtonClicked
  | LongFormEvents.VIEW_CUSTOMER_ID_PROGRAM
  | LongFormEvents.VIEW_TERMS_AND_CONDITIONS
  | LongFormEvents.VIEW_PREQUALIFICATION_NOTICE
  | LongFormEvents.VIEW_AUTHORIZED_USER_TERMS
  | LongFormEvents.APPROVED
  | LongFormEvents.DECLINED_OR_ERROR
  | LongFormEvents.COMPLETE_APP_LATER
  | LongFormEvents.SELECT_PLAID
  | LongFormEvents.SELECT_MANUAL
  | LongFormEvents.FETCH_LINK_TOKEN
  | LongFormEvents.PLAID_MODAL_LOADING;

type LongFormEventsData = {
  target?: LongFormStates;
  softDeclineError?: SoftDecline;
  hardDeclineError?: HardDecline;
  eligibilityDetails?: CustomerEligibility;
  isCurrentApplyTypeOTB?: boolean;
  updateBankAccountErrors?: UpdateBankAccountErrors[];
  generateBankConnectorTokenErrors?: GenerateBankConnectorTokenErrors[];
  publicToken?: string;
  order?: Order;
};

export interface LongFormMachineEvents extends EventObject {
  type: LongFormEventsTypeSchema;
  data?: LongFormEventsData;
}

export enum LongFormGuardsType {
  LEASE_DECLINED = 'LeaseDecline',
  LOAN_DECLINED = 'LoanDecline',
  LTO_INELIGIBLE = 'LTOIneligible',
  APP_APPROVED = 'AppApproved',
  GENERAL_APPLY_ERRORS = 'GeneralApplyErrors',
  SOFT_DECLINE = 'SoftDecline',
}
type LongFormGuardsTypeSchema =
  | LongFormGuardsType.LEASE_DECLINED
  | LongFormGuardsType.LOAN_DECLINED
  | LongFormGuardsType.LTO_INELIGIBLE
  | LongFormGuardsType.GENERAL_APPLY_ERRORS
  | LongFormGuardsType.SOFT_DECLINE
  | LongFormGuardsType.APP_APPROVED;

export interface LongFormMachineGuards extends EventObject {
  type: LongFormGuardsTypeSchema;
}
