import { ApolloQueryResult, gql } from '@apollo/client';
import { apolloMutation, apolloQuery } from '../apollo';
import {
  UpdateDebitInfoInput,
  UpdateBankAccountInput,
  UpdateShippingAddressInput,
  SignLeaseAgreementInput,
  CustomerSearchInput,
  RemoveVirtualCardInput,
  SelectLeaseOptionInput,
  ApplyInput,
  GenerateLeaseContractInput,
  UpdateEmploymentInput,
  VerifyEmailResponse,
  CreateDraftAppInput,
  AuthorizeCardInput,
  UpdateBasicInfoContinuedInput,
  GetAdditionalCustomerInfoQuery,
} from '../gql/graphql';

export interface ApplicationForm {
  apply(input: ApplyInput): Promise<object>;
  updateShippingAddress(input: UpdateShippingAddressInput): Promise<object>;
  generateContract(input: GenerateLeaseContractInput): Promise<object>;
  updateBasicInfoContinued(input: UpdateBasicInfoContinuedInput): Promise<object>;
  updateEmployment(input: UpdateEmploymentInput): Promise<object>;
  authorizeCard(input: AuthorizeCardInput): Promise<object>;
  updateDebitInfo(input: UpdateDebitInfoInput): Promise<object>;
  updateBankAccount(input: UpdateBankAccountInput): Promise<object>;
  signLeaseAgreement(input: SignLeaseAgreementInput): Promise<object>;
  customer(input: CustomerSearchInput): Promise<object>;
  verifyEmail(email: string): Promise<ApolloQueryResult<VerifyEmailResponse>>;
  removeVirtualCard(input: RemoveVirtualCardInput): Promise<object>;
  selectLeaseOption(input: SelectLeaseOptionInput): Promise<object>;
  createDraftApp(input: CreateDraftAppInput): Promise<object>;
  getAdditionalCustomerInfo(applicationId: number): Promise<ApolloQueryResult<GetAdditionalCustomerInfoQuery>>;
}

const applicationFormService: ApplicationForm = {
  async apply(input: ApplyInput) {
    return apolloMutation(apply, input);
  },
  async updateShippingAddress(input: any) {
    return apolloMutation(UPDATE_SHIPPING_ADDRESS, { input: input });
  },
  async generateContract(input: GenerateLeaseContractInput) {
    return apolloMutation(generateLeaseContract, input);
  },
  async updateBasicInfoContinued(input: UpdateBasicInfoContinuedInput) {
    return apolloMutation(UPDATE_BASIC_INFO_CONTINUED, { input: input });
  },
  async updateEmployment(input: UpdateEmploymentInput) {
    return apolloMutation(updateEmployment, input);
  },
  async updateDebitInfo(input: UpdateDebitInfoInput) {
    return apolloMutation(UPDATE_DEBIT_DETAILS, { input: input });
  },
  async authorizeCard(input: AuthorizeCardInput) {
    return apolloMutation(AUTHORIZE_CARD, { input: input });
  },
  async updateBankAccount(input: UpdateBankAccountInput) {
    return apolloMutation(UPDATE_BANK_ACCOUNT, { input: input });
  },
  async signLeaseAgreement(input: SignLeaseAgreementInput) {
    return apolloMutation(SIGN_LEASE_AGREEMENT, { input: input });
  },
  async customer(input: CustomerSearchInput) {
    return apolloQuery(customerSearch, { input: input });
  },
  async verifyEmail(email: string): Promise<ApolloQueryResult<VerifyEmailResponse>> {
    return (await apolloQuery(verifyEmailQuery, { email })) as ApolloQueryResult<VerifyEmailResponse>;
  },
  async removeVirtualCard(input: RemoveVirtualCardInput) {
    return apolloMutation(REMOVE_VIRTUAL_CARD, { input: input });
  },
  async selectLeaseOption(input: SelectLeaseOptionInput) {
    return apolloMutation(SELECT_LEASE_OPTION, { input: input });
  },
  async createDraftApp(input: CreateDraftAppInput) {
    return apolloMutation(CREATE_DRAFT_APP, { input: input });
  },
  async getAdditionalCustomerInfo(applicationId: number) {
    return (await apolloQuery(getAdditionalCustomerInfoQuery, { applicationId })) as ApolloQueryResult<GetAdditionalCustomerInfoQuery>;
  },
};

export const apply = gql`
  mutation apply($applyInput: ApplyInput!) {
    apply(input: $applyInput) {
      application {
        loan {
          id
          summary {
            id
            finalPaymentAmount
            paymentAmount
            firstPaymentDue
            annualPercentageRate
            financeCharge
            termLength
            amountFinanced
            loanAmount
            deferredInterestPeriod
          }
          achDetails {
            routingNumber
            accountNumberLast4
          }
          status
          isOtb
          maxLoanAmount
          cartValidationDetails
          options {
            productID
            deferredInterestPeriod
            lineAmount
            merchantDiscountRate
            minimumSpend
            rate
            termLength
          }
        }
        lease {
          id
          status
          declineReason
          decisionCode
          displayId
          publicId
          draftStep
          appStep
          isOtb
          maxLeaseAmount
          cartValidationDetails
          neuroIdIdentityId
          ipUpperBoundary
          ipBehavior
          options {
            leaseTerm
            sacTerm
            earlyBuyoutDiscount
            approvedAmount
            applicationFee
            ipUpperBoundary
            minimumSpend
            authCapture
          }
          employment {
            payPeriod
            lastPayDate
            nextPayDate
            monthlyIncome
            isPaidViaDirectDeposit
          }
          card {
            isExpired
            provider
            lastFour
            expirationDate
          }
          bank {
            accountNumberLast4
            routingNumber
          }
          contractSummaries {
            leaseTerm
            leaseAmount
            cashPlusPrice
            cashPlusTerm
            cashPlusFee
            initialPaymentWithTax
            initialPaymentAmount
            paymentFrequency
            tax
            totalCostOfOwnership
            scheduledPaymentAmounts
          }
        }
        customer {
          id
          firstName
          lastName
          cellPhone
          emailAddress
          birthDate
          tokenizedTaxId
          billingAddress {
            line1
            line2
            city
            state
            zip
          }
          shippingAddress {
            line1
            line2
            city
            state
            zip
          }
        }
      }
      applicationErrors {
        __typename
        ... on DenialError {
          message
        }
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on HardDecline {
          message
          extensions {
            decisionCode
          }
        }
        ... on SoftDecline {
          message
          extensions {
            decisionCode
            declineField
            appId
          }
        }
        ... on LeaseRestricted {
          message
        }
        ... on CartValidation {
          message
          extensions {
            validationErrType
            approvalType
            approvedAmount
            minimumSpend
          }
        }
      }
    }
  }
`;

export const UPDATE_SHIPPING_ADDRESS = gql`
  mutation updateShippingAddress($input: UpdateShippingAddressInput!) {
    updateShippingAddress(input: $input) {
      address {
        line1
        line2
        city
        state
        zip
      }
      updateAddressErrors {
        __typename
        ... on FatalError {
          message
        }
      }
    }
  }
`;

export const generateLeaseContract = gql`
  mutation generateLeaseContract($generateContract: GenerateLeaseContractInput!) {
    generateLeaseContract(input: $generateContract) {
      leaseDocuments {
        welcomeLetter
        leaseAgreement
      }
      generateContractErrors {
        __typename
        ... on FatalError {
          message
          path
        }
        ... on BadRequest {
          message
          path
        }
      }
    }
  }
`;

export const UPDATE_BASIC_INFO_CONTINUED = gql`
  mutation updateBasicInfoContinued($input: UpdateBasicInfoContinuedInput!) {
    updateBasicInfoContinued(input: $input) {
      application {
        loan {
          id
          summary {
            id
            finalPaymentAmount
            paymentAmount
            firstPaymentDue
            annualPercentageRate
            financeCharge
            termLength
            amountFinanced
            loanAmount
            deferredInterestPeriod
          }
          achDetails {
            routingNumber
            accountNumberLast4
          }
          status
          isOtb
          maxLoanAmount
          cartValidationDetails
          options {
            productID
            deferredInterestPeriod
            lineAmount
            merchantDiscountRate
            minimumSpend
            rate
            termLength
          }
        }
        lease {
          id
          status
          declineReason
          decisionCode
          displayId
          publicId
          draftStep
          appStep
          isOtb
          maxLeaseAmount
          cartValidationDetails
          neuroIdIdentityId
          ipUpperBoundary
          ipBehavior
          options {
            leaseTerm
            sacTerm
            earlyBuyoutDiscount
            approvedAmount
            applicationFee
            ipUpperBoundary
            minimumSpend
            authCapture
          }
          employment {
            payPeriod
            lastPayDate
            nextPayDate
            monthlyIncome
            isPaidViaDirectDeposit
          }
          card {
            isExpired
            provider
            lastFour
            expirationDate
          }
          bank {
            accountNumberLast4
            routingNumber
          }
          contractSummaries {
            leaseTerm
            leaseAmount
            cashPlusPrice
            cashPlusTerm
            cashPlusFee
            initialPaymentWithTax
            initialPaymentAmount
            paymentFrequency
            tax
            totalCostOfOwnership
            scheduledPaymentAmounts
          }
        }
        customer {
          id
          firstName
          lastName
          cellPhone
          emailAddress
          birthDate
          tokenizedTaxId
          billingAddress {
            line1
            line2
            city
            state
            zip
          }
          shippingAddress {
            line1
            line2
            city
            state
            zip
          }
        }
      }
      updateBasicInfoContinuedErrors {
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on NotFound {
          message
        }
      }
    }
  }
`;

export const updateEmployment = gql`
  mutation updateEmployment($employmentData: UpdateEmploymentInput!) {
    updateEmployment(input: $employmentData) {
      updateEmploymentErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BusinessError {
          message
        }
      }
    }
  }
`;

export const UPDATE_DEBIT_DETAILS = gql`
  mutation updateDebitInfo($input: UpdateDebitInfoInput!) {
    updateDebitInfo(input: $input) {
      updateDebitInfoErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on HardDecline {
          message
        }
        ... on SoftDecline {
          message
          extensions {
            declineType
          }
        }
      }
    }
  }
`;

export const AUTHORIZE_CARD = gql`
  mutation authorizeCard($input: AuthorizeCardInput!) {
    authorizeCard(input: $input) {
      application {
        loan {
          id
          summary {
            id
            finalPaymentAmount
            paymentAmount
            firstPaymentDue
            annualPercentageRate
            financeCharge
            termLength
            amountFinanced
            loanAmount
            deferredInterestPeriod
          }
          achDetails {
            routingNumber
            accountNumberLast4
          }
          status
          isOtb
          maxLoanAmount
          cartValidationDetails
          options {
            productID
            deferredInterestPeriod
            lineAmount
            merchantDiscountRate
            minimumSpend
            rate
            termLength
          }
        }
        lease {
          id
          status
          declineReason
          decisionCode
          displayId
          publicId
          draftStep
          appStep
          isOtb
          maxLeaseAmount
          cartValidationDetails
          neuroIdIdentityId
          ipUpperBoundary
          ipBehavior
          options {
            leaseTerm
            sacTerm
            earlyBuyoutDiscount
            approvedAmount
            applicationFee
            ipUpperBoundary
            minimumSpend
            authCapture
          }
          employment {
            payPeriod
            lastPayDate
            nextPayDate
            monthlyIncome
            isPaidViaDirectDeposit
          }
          card {
            isExpired
            provider
            lastFour
            expirationDate
          }
          bank {
            accountNumberLast4
            routingNumber
          }
          contractSummaries {
            leaseTerm
            leaseAmount
            cashPlusPrice
            cashPlusTerm
            cashPlusFee
            initialPaymentWithTax
            initialPaymentAmount
            paymentFrequency
            tax
            totalCostOfOwnership
            scheduledPaymentAmounts
          }
        }
        customer {
          id
          firstName
          lastName
          cellPhone
          emailAddress
          birthDate
          tokenizedTaxId
          billingAddress {
            line1
            line2
            city
            state
            zip
          }
          shippingAddress {
            line1
            line2
            city
            state
            zip
          }
        }
      }
      authorizeCardErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on HardDecline {
          message
          extensions {
            decisionCode
          }
        }
        ... on SoftDecline {
          message
          extensions {
            decisionCode
          }
        }
      }
    }
  }
`;

export const UPDATE_BANK_ACCOUNT = gql`
  mutation updateBankAccount($input: UpdateBankAccountInput!) {
    updateBankAccount(input: $input) {
      updateBankAccountErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on HardDecline {
          message
        }
        ... on SoftDecline {
          message
          extensions {
            declineType
            displayText
          }
        }
        ... on BankConnectionError {
          message
          extensions {
            bankConnectionType
            errCode
            displayText
          }
        }
      }
    }
  }
`;

export const SELECT_LEASE_OPTION = gql`
  mutation selectLeaseOption($input: SelectLeaseOptionInput!) {
    selectLeaseOption(input: $input) {
      selectLeaseOptionErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on NotFound {
          message
        }
      }
      leaseContractSummary {
        leaseTerm
        leaseAmount
        cashPlusPrice
        cashPlusTerm
        cashPlusFee
        initialPaymentWithTax
        initialPaymentAmount
        paymentFrequency
        tax
        totalCostOfOwnership
        scheduledPaymentAmounts
      }
    }
  }
`;

export const SIGN_LEASE_AGREEMENT = gql`
  mutation signLeaseAgreement($input: SignLeaseAgreementInput!) {
    signLeaseAgreement(input: $input) {
      signingErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on NotSigned {
          message
        }
      }
    }
  }
`;

export const REMOVE_VIRTUAL_CARD = gql`
  mutation removeVirtualCard($input: RemoveVirtualCardInput!) {
    removeVirtualCard(input: $input) {
      removeVirtualCardErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on HasAuthError {
          message
        }
      }
    }
  }
`;

const CREATE_DRAFT_APP = gql`
  mutation createDraftApp($input: CreateDraftAppInput!) {
    createDraftApp(input: $input) {
      leaseApplicationId
      customerId
      createDraftAppErrors {
        __typename
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
        ... on NotFound {
          message
        }
      }
    }
  }
`;

const customerSearch = gql`
  query customer($input: CustomerSearchInput!) {
    customer(input: $input) {
      customers {
        id
        firstName
        lastName
        cellPhone
        emailAddress
        tokenizedTaxId
        birthDate
        billingAddress {
          line1
          line2
          city
          state
          zip
        }
      }
      eligibility {
        applyTypes
        otbProducts {
          loanOptions {
            productID
            deferredInterestPeriod
            lineAmount
            merchantDiscountRate
            minimumSpend
            rate
            termLength
          }
          leaseOptions {
            leaseTerm
            approvedAmount
            applicationFee
            ipUpperBoundary
            earlyBuyoutDiscount
            sacTerm
            minimumSpend
            authCapture
          }
        }
        isEligible
        isLoanEligible
        isLeaseEligible
        eligibilityCode
        otbCode
        application {
          loan {
            id
            summary {
              id
              finalPaymentAmount
              paymentAmount
              firstPaymentDue
              annualPercentageRate
              financeCharge
              termLength
              amountFinanced
              loanAmount
              deferredInterestPeriod
            }
            achDetails {
              routingNumber
              accountNumberLast4
            }
            status
            isOtb
            maxLoanAmount
            cartValidationDetails
            options {
              productID
              deferredInterestPeriod
              lineAmount
              merchantDiscountRate
              minimumSpend
              rate
              termLength
            }
          }
          lease {
            id
            status
            declineReason
            decisionCode
            displayId
            publicId
            draftStep
            appStep
            isOtb
            maxLeaseAmount
            cartValidationDetails
            neuroIdIdentityId
            ipUpperBoundary
            ipBehavior
            options {
              leaseTerm
              sacTerm
              earlyBuyoutDiscount
              approvedAmount
              applicationFee
              ipUpperBoundary
              minimumSpend
              authCapture
            }
            employment {
              payPeriod
              lastPayDate
              nextPayDate
              monthlyIncome
              isPaidViaDirectDeposit
            }
            card {
              isExpired
              provider
              lastFour
              expirationDate
            }
            bank {
              accountNumberLast4
              routingNumber
            }
            contractSummaries {
              leaseTerm
              leaseAmount
              cashPlusPrice
              cashPlusTerm
              cashPlusFee
              initialPaymentWithTax
              initialPaymentAmount
              paymentFrequency
              tax
              totalCostOfOwnership
              scheduledPaymentAmounts
            }
          }
          customer {
            id
            firstName
            lastName
            cellPhone
            emailAddress
            birthDate
            tokenizedTaxId
            billingAddress {
              line1
              line2
              city
              state
              zip
            }
            shippingAddress {
              line1
              line2
              city
              state
              zip
            }
          }
        }
      }
      customerSearchErrors {
        __typename
      }
    }
  }
`;

const verifyEmailQuery = gql`
  query verifyEmail($email: String!) {
    verifyEmail(email: $email) {
      verifyEmailErrors {
        __typename
        ... on InvalidEmailError {
          message
        }
        ... on FatalError {
          message
        }
        ... on BadRequest {
          message
        }
      }
    }
  }
`;

const getAdditionalCustomerInfoQuery = gql`
  query GetAdditionalCustomerInfo($applicationId: Int!) {
    getAdditionalCustomerInfo(applicationId: $applicationId) {
      fullTaxId
      fullBankAccount
      cardNumberWithBin
    }
  }
`;

export default applicationFormService;
